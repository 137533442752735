import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/shared/models/api-response";
import { DataUsage } from "src/app/modules/dashboard/modules/admin/models/data-usage";
import { Member } from "src/app/shared/models/member";
import { NewSubscriptionRequest } from "../models/new-subscription-request";
import { SubscriptionRequest } from "../models/subscription-request";
import { SubscriptionList } from "../models/subscription-list";
import { FirebaseAuthService } from "src/app/shared/services/firebase-auth.service";
import { User } from "@angular/fire/auth";


@Injectable()
export class SubscriptionHttpService {
    adminUid: string;

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly firebaseAuthService: FirebaseAuthService
    ) {
        this.firebaseAuthService.user.subscribe((user: User) => {
            this.adminUid = user.uid;
        });
    }

    public getSubscriptions(uid: string, iccId: string): Observable<SubscriptionList> {
        return this.httpClient.get<SubscriptionList>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}`);
    }

    public giveSubscription(uid: string, iccId: string, planType: string, country: string, numberOfDays: number): Observable<ApiResponse<any[]>> {
        const requestBody: NewSubscriptionRequest = {
            "planType": planType,
            "country": country,
            "numberOfDays": numberOfDays,
          }
        return this.httpClient.post<ApiResponse<any[]>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}`, requestBody);
    }

    public deleteSubscription(uid: string, iccId: string, transactionId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.delete<ApiResponse<any>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/transactionId/${transactionId}`);
    }

    public deleteFutureSubscription(uid: string, iccId: string, transactionId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.delete<ApiResponse<any>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/futureTransactionId/${transactionId}`);
    }

    public deletePaidSubscription(uid: string, iccId: string, requestId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.delete<ApiResponse<any>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/paymentRequest/${requestId}`);
    }

    public deleteUserSubscriptionHistory(uid: string, iccId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.delete<ApiResponse<any>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/clean-history`);
    }

    public paySubscription(uid: string, iccId: string, requestId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/paymentRequest/${requestId}/paid`, {});
    }
    public cleanSubscriptions(uid: string, iccId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.delete<ApiResponse<any>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/clean`);
    }

    public addDaysToSubscription(uid: string, iccId: string, numberOfDays: number, forFuture?: boolean): Observable<ApiResponse<any[]>> {
        const requestBody: any = {
            "numberOfDays": numberOfDays,
            "forFuture": forFuture
          }
        return this.httpClient.put<ApiResponse<any[]>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/add-days`, requestBody);
    }

    public activateFutureSubscription(uid: string, iccId: string, transactionId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.put<ApiResponse<any[]>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/transactionId/${transactionId}/activate`, {});
    }

    public pausePremiumSubscription(uid: string, iccId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.put<ApiResponse<any[]>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/pause`, {});
    }

    public topUpPremiumSubscription(uid: string, iccId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.put<ApiResponse<any[]>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/top-up`, {});
    }

    public exhaustSubscription(uid: string, iccId: string, transactionId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.put<ApiResponse<any[]>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/transactionId/${transactionId}/exhaust`, {});
    }

    public expireSubscription(uid: string, iccId: string, transactionId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.put<ApiResponse<any[]>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/transactionId/${transactionId}/expire`, {});
    }

    public retryAddingAddOn(uid: string, iccId: string, transactionId: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any[]>>(`api/backoffice/subscriptions/${this.adminUid}/user/${uid}/iccId/${iccId}/transactionId/${transactionId}/retryAddOn`, {});
    }
}
