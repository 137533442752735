import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/shared/models/api-response";
import { FirebaseAuthService } from "src/app/shared/services/firebase-auth.service";

@Injectable()
export class UserHttpService {
        adminUid: string;

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly firebaseAuthService: FirebaseAuthService
    ) { 
        this.firebaseAuthService.user.subscribe((user: User) => {
            this.adminUid = user.uid;
        });
    }

    public searchUsersByEmail(email: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>("/api/backoffice/users/findUser", {"email": email});
    }

    public searchUsersByPhone(phone: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>("/api/backoffice/users/findUser", {"phoneNumber": phone});
    }

    public getUserByIccId(iccId: string): Observable<ApiResponse<any>> {
        return this.httpClient.get<ApiResponse<any>>(`api/backoffice/users/iccId/${iccId}`);
    }

    public getUserByUid(uid: string): Observable<ApiResponse<any>> {
        return this.httpClient.get<ApiResponse<any>>(`api/backoffice/users/${uid}`);
    }

    public newSim(uid: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>(`api/backoffice/users/${uid}/assign-esim`, {});
    }

    public reuseESim(uid: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>(`api/backoffice/users/${uid}/reuse-esim`, {});
    }

    public deleteUser(uid: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.delete<ApiResponse<any>>(`api/backoffice/users/${uid}`);
    }

    public getPaymentHistory(uid: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.get<ApiResponse<any>>(`api/backoffice/payments/${uid}`);
    }

    public blockUser(uid: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>(`api/backoffice/users/${this.adminUid}/user/${uid}/block`, {});
    }

    public unblockUser(uid: string): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>(`api/backoffice/users/${this.adminUid}/user/${uid}/unblock`, {});
    }
}
