import { NgModule } from "@angular/core";
import { AdminModule } from "./modules/admin/admin.module";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { DirectiveModule } from "src/app/shared/modules/directive.module";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        DashboardRoutingModule,
        CommonModule,
        NgbModule,
        FormsModule,
        AdminModule,
        DirectiveModule
    ]
})
export class DashboardModule { }
