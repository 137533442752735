import { Component, OnInit } from '@angular/core';
import { ErrorTrackerService } from 'src/app/shared/services/error-tracker.service';
import { RequestTrackerService } from 'src/app/shared/services/request-tracker.service';
import * as bootstrap from "bootstrap";
import { PlanType } from '../../enums/plan-type';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { SubscriptionHttpService } from '../../services/subscription.http.service';

@Component({
  selector: 'app-error-tracker',
  templateUrl: './error-tracker.component.html',
  styleUrls: ['./error-tracker.component.scss']
})
export class ErrorTrackerComponent implements OnInit {
  public planTypes = PlanType;
  public fetchedMissings: boolean = false;
  public fetchedFaileds: boolean = false;

  public missingProcessableSubscriptions: any[] = [];
  public failedProcessableSubscriptions: any[] = [];

  constructor(private errorTrackerService: ErrorTrackerService,
    private readonly requestTrackerService: RequestTrackerService,
    private readonly toasterService: ToasterService,
    private readonly subscriptionHttpService: SubscriptionHttpService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.refresh();
    });
  }

  public getMissingIds(): void {
    this.missingProcessableSubscriptions = [];
    this.errorTrackerService.getMissingIds()
      .subscribe((response: any) => {
        this.missingProcessableSubscriptions = [];
        const missingSubscriptions = Object.values(response.aggragatedMissings);
        missingSubscriptions.forEach((sub: any) => {
          const subMillisecondsCreatedAt = sub.createdAt._seconds * 1000 + sub.createdAt._nanoseconds / 1000000;
          const subMillisecondsUpdatedAt = sub.updatedAt._seconds * 1000 + sub.updatedAt._nanoseconds / 1000000;

          const now = Date.now();
          const twoMinutes = 2 * 60 * 1000;
          if (now - subMillisecondsUpdatedAt > twoMinutes) {
            sub.createdAt = new Date(subMillisecondsCreatedAt);;
            sub.updatedAt = new Date(subMillisecondsUpdatedAt);
            this.missingProcessableSubscriptions.push(sub);
          }
        });
        this.initialiseToolTips();
        this.fetchedMissings = true;
      });
  }

  public getFaileds(): void {
    this.failedProcessableSubscriptions = [];
    this.errorTrackerService.getFaileds()
      .subscribe((response: any) => {
        this.failedProcessableSubscriptions = [];
        const failedSubs = Object.values(response.failedSubscriptions);
        failedSubs.forEach((sub: any) => {
          const subMilliseconds = sub.createdAt._seconds * 1000 + sub.createdAt._nanoseconds / 1000000;
          const date = new Date(subMilliseconds);
          sub.createdAt = date;
          this.failedProcessableSubscriptions.push(sub);
        });
        this.fetchedFaileds = true;
      });
  }

  public deleteProcessableSubscription(transactionId: string): void {
    this.errorTrackerService.deleteProcessableSubscription(transactionId)
      .subscribe(() => {
        this.getMissingIds();
        this.getFaileds();
        this.toasterService.success(
          "Processable subscription is deleted"
        );
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      })
  }

  public reprocessProcessableSubscription(transactionId: string): void {
    this.errorTrackerService.reprocessProcessable(transactionId)
      .subscribe(() => {
        this.getMissingIds();
        this.getFaileds();
        this.toasterService.success(
          "Processable subscription is going to be reprocessed"
        );
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      })
  }

  public isLoading(): boolean {
    return this.requestTrackerService.isLoading();
  }

  private initialiseToolTips(): void {
    setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      })
    });
  }

  public refresh(): void {
    this.fetchedFaileds = false;
    this.fetchedMissings = false;
    this.getMissingIds();
    this.getFaileds();
  }

  public retryAddOn(subscription: any): void {
    this.subscriptionHttpService.retryAddingAddOn(subscription.uid, subscription.iccId, subscription.transactionId).subscribe(response => {
      this.refresh();
      this.toasterService.success(
        "Subscription request is sent to the provider again"
      );
    },
    (error) => {
      this.toasterService.error(`ERROR: ${error.error.message}`);
    });
  }


}
