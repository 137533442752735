import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { RequestTrackerService } from "src/app/shared/services/request-tracker.service";
import { FileUploadPurpose } from "../../enums/file-upload-purpose";
import { ToasterService } from "src/app/shared/services/toaster.service";

@Component({
    selector: "tata-lifecycle",
    templateUrl: "./tata-lifecycle.component.html",
    styleUrls: ["./tata-lifecycle.component.scss"]
})
export class TataLifecycleComponent implements OnInit {

    @ViewChild("fileInput")
    public fileInput: ElementRef<HTMLInputElement>;

    // @ViewChild()
    // public nameInput: ElementRef;

    public readonly FileUploadPurpose: typeof FileUploadPurpose = FileUploadPurpose;

    // yikes, stateful
    private currentPurpose: FileUploadPurpose = null;
    // private selectedNotification: string = null;

    public response: string = "Waiting for request...";

    public constructor(
        private readonly toasterService: ToasterService,
        private readonly requestTrackerService: RequestTrackerService
    ) { }

    public async ngOnInit(): Promise<void> {

    }

    public isLoading(): boolean {
        return this.requestTrackerService.isLoading();
    }

    public openFileInput(purpose: FileUploadPurpose): void {
        this.currentPurpose = purpose;
        this.fileInput.nativeElement.click();
    }

    public onFileSelected(event: Event): void {
        const fileInput: HTMLInputElement = event.target as HTMLInputElement;
        const files: FileList = fileInput.files;
        const selectedFile: File = files[0];

        switch (this.currentPurpose) {
            case FileUploadPurpose.ESIMS:
                this.uploadESIMs(selectedFile);
                break;
            case FileUploadPurpose.PLANS:
                this.importPlans(selectedFile);
                break;
            default:
                throw new Error("Invalid state!");
        }

        this.currentPurpose = null;
    }

    public onNotificationSelected(value: string): void {
        // this.selectedNotification = value;
    }

    public uploadESIMs(esimFile: File): void {
        
    }

    public processESIMs(esimFilePath: string): void {
        
    }

    public importPlans(planFile: File): void {
        
    }

    public welcome(): void {
        
    }

    public createDevice(): void {
        
    }
    
    public editUserName(): void {
        
    }

    public deleteUser(): void {
        
    }
}
