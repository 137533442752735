import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FirebaseAuthService } from "./firebase-auth.service";
import { User } from "@angular/fire/auth";

@Injectable({
    providedIn: "root"
})
export class ErrorTrackerService {
    adminUid: string;

    public constructor(
        private readonly httpClient: HttpClient,
        private readonly firebaseAuthService: FirebaseAuthService
    ) {
        this.firebaseAuthService.user.subscribe((user: User) => {
            this.adminUid = user.uid;
        });
    }

    public getMissingIds(): Observable<any> {
        return this.httpClient.get<any>(`/api/backoffice/plans/${this.adminUid}/missing-ids`);
    }

    public getFaileds(): Observable<any> {
        return this.httpClient.get<any>(`/api/backoffice/plans/${this.adminUid}/faileds`);
    }

    public getEsimsInFault(): Observable<any> {
        return this.httpClient.get<any>(`/api/backoffice/esims/${this.adminUid}/inFault`);
    }

    public reprocessProcessable(transactionId: string): Observable<any> {
        return this.httpClient.post<any>(`/api/backoffice/plans/${this.adminUid}/transactionId/${transactionId}/reprocess`, {});
    }

    public deleteProcessableSubscription(transactionId: string): Observable<any> {
        return this.httpClient.delete<any>(`/api/backoffice/plans/${this.adminUid}/transactionId/${transactionId}`);
    }
}
