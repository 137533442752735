import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-byte-flow',
  templateUrl: './byte-flow.component.html',
  styleUrls: ['./byte-flow.component.scss']
})
export class ByteFlowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
