import { Component, OnInit } from '@angular/core';
import SwaggerUI from 'swagger-ui'

@Component({
  selector: 'app-partners-api',
  template: '<div id="swagger-ui"></div>',
  styleUrls: ['./partners-api.component.scss']
})
export class PartnersApiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    SwaggerUI({
      domNode: document.getElementById('swagger-ui'),
      url: 'test.yaml', // Path to your OpenAPI file
      presets: [
        SwaggerUI.presets.apis,
      ],
    });
  }

}
