import { Component, OnInit, ViewChild } from "@angular/core";
import { RequestTrackerService } from "src/app/shared/services/request-tracker.service";
import { UserHttpService } from "../../services/user.http.service";
import { SubscriptionHttpService } from "../../services/subscription.http.service";
import * as bootstrap from "bootstrap";
import { ToasterService } from "src/app/shared/services/toaster.service";
import { User } from "../../models/users/user";
import { UserManagementModal } from "../../enums/user-management-modal";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {
  @ViewChild("closebutton") closebutton;
  @ViewChild("mgmtSubs") mgmtSubs;


  selectedSearchOption: string = "Email";
  searchInput: string = "";
  numberOfDays: number = 1;
  listUsersResponse: User[] = [];
  user: User;

  showModals: boolean = false;
  modalOptions = UserManagementModal;
  selectedModal: UserManagementModal;


  constructor(
    private readonly requestTrackerService: RequestTrackerService,
    private readonly userHttpService: UserHttpService,
    private readonly subscriptionHttpService: SubscriptionHttpService,
    private readonly toasterService: ToasterService
  ) { }

  ngOnInit(): void {
  }

  private initialiseToolTips(): void {
    setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      })
    });
  }

  public getUserUid(): void {
    this.showModals = false;
    switch (this.selectedSearchOption) {
      case "Email":
        this.getUserByEmailAddress();
        break;
      case "Phone":
        this.getUserByPhoneNumber();
        break;
      case "ICCID":
        this.getUserByICCID();
        break;
      case "UID":
        this.getUserByUID();
        break;
      default:
        throw new Error("Invalid state!");
    }
  }

  public getUserByEmailAddress(): void {
    this.userHttpService.searchUsersByEmail(this.searchInput).subscribe(
      (response) => {
        this.listUsersResponse = [];

        if (response.data.length === 0) {
          this.noUserFound();
          return;
        }
        console.log(response.data);
        response.data.forEach((user) => {
          let newUser = user;
          if (user.devices) {
            let userDevice = Object.values(user.devices)[0];
            newUser.devices = [];
            newUser.devices.push(userDevice);
          }

          this.listUsersResponse.push(newUser);
        });
        this.initialiseToolTips();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public getUserByPhoneNumber(): void {
    this.userHttpService.searchUsersByPhone(this.searchInput).subscribe(
      (response) => {
        this.listUsersResponse = [];

        if (response.data.length === 0) {
          this.noUserFound();
          return;
        }

        response.data.forEach((user) => {
          let newUser = user;
          if (user.devices) {
            let userDevice = Object.values(user.devices)[0];
            newUser.devices = [];
            newUser.devices.push(userDevice);
          }

          this.listUsersResponse.push(newUser);
        });
        this.initialiseToolTips();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public getUserByUID(): void {
    this.userHttpService.getUserByUid(this.searchInput).subscribe(
      (response) => {
        this.listUsersResponse = [];

        if (!response.data) {
          this.noUserFound();
          return;
        }

        let newUser = response.data;
        if (response.data.devices) {
          let userDevice = Object.values(response.data.devices)[0];
          newUser.devices = [];
          newUser.devices.push(userDevice);
        }
        this.listUsersResponse.push(newUser);
        this.initialiseToolTips();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public getUserByICCID(): void {
    this.userHttpService.getUserByIccId(this.searchInput).subscribe(
      (response) => {
        this.listUsersResponse = [];

        if (!response.data) {
          this.noUserFound();
          return;
        }

        let newUser = response.data;
        if (response.data.devices) {
          let userDevice = Object.values(response.data.devices)[0];
          newUser.devices = [];
          newUser.devices.push(userDevice);
        }
        this.listUsersResponse.push(newUser);
        this.initialiseToolTips();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public manageUser(user: User, modal: UserManagementModal): void {
    this.showModals = true;
    const tooltipElements = Array.from(document.querySelectorAll('.bs-tooltip-auto'));
    for (const item of tooltipElements) {
      item.remove();
    }
    this.user = user;
    this.selectedModal = modal;
    this.initialiseToolTips();
  }

  public deleteAllSubscriptions(): void {

    this.subscriptionHttpService.cleanSubscriptions(this.user.uid, this.user.esim.iccId).subscribe(
      (response) => {
        this.toasterService.success(
          "User's subscriptions cleaned successfully."
        );
        this.closebutton.nativeElement.click();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public deleteUserDataFully(): void {
    this.deleteUserData();
  }

  public deleteUserData(): void {

    this.userHttpService.deleteUser(this.user.uid).subscribe(
      () => {
        this.toasterService.success(
          "User's all data cleaned successfully."
        );
        this.closebutton.nativeElement.click();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public deleteUserSubscriptionHistory(): void {

    this.subscriptionHttpService.deleteUserSubscriptionHistory(this.user.uid, this.user.esim.iccId).subscribe(
      () => {
        this.toasterService.success(
          "User's past subscriptions cleaned successfully."
        );
        this.closebutton.nativeElement.click();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public newEsim(): void {
    this.userHttpService.newSim(this.user.uid).subscribe(
      (response) => {
        this.toasterService.success("User is assigned a new e-sim");
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public reuseEsim(): void {
    this.userHttpService.reuseESim(this.user.uid).subscribe(
      (response) => {
        this.toasterService.success("User's e-sim is reusable");
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public blockUser(): void {
    this.userHttpService.blockUser(this.user.uid).subscribe(
      (response) => {
        this.toasterService.success("User is blocked");
        this.closebutton.nativeElement.click();
        this.getUserUid();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public unblockUser(uid: string): void {
    this.userHttpService.unblockUser(uid).subscribe(
      (response) => {
        this.toasterService.success("User is unblocked");
        this.getUserUid();
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public noUserFound(): void {
    this.toasterService.warn(
      "No user found with the given search parameters"
    );
  }

  public isLoading(): boolean {
    return this.requestTrackerService.isLoading();
  }
}
