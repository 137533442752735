import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { SortColumn, SortDirection, SortEvent } from "../events/sort.event";

const rotate: { [key: string]: SortDirection } = { "": "asc", "asc": "desc", "desc": "" };

@Directive({
    selector: "th[sortable]",
    host: {
        "[class.asc]": "direction === 'asc'",
        "[class.desc]": "direction === 'desc'",
        "[class.cursor-pointer]": "true",
        "(click)": "rotate()"
    }
})
export class SortableDirective<T> {
    
    @Input()
    public sortable: SortColumn<T> = "";

    @Output()
    public sort = new EventEmitter<SortEvent<T>>();

    public direction: SortDirection = "";

    public rotate(): void {
        this.direction = rotate[this.direction];
        
        this.sort.emit({
            column: this.sortable,
            direction: this.direction
        });
    }
}
