import { Component, Input, OnInit } from '@angular/core';
import { SettingsHttpService } from '../../services/settings.http.service';
import { ByteFlowSettings } from '../../models/settings/byte-flow-settings';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-edit-byte-flow-settings',
  templateUrl: './edit-byte-flow-settings.component.html',
  styleUrls: ['./edit-byte-flow-settings.component.scss']
})
export class EditByteFlowSettingsComponent implements OnInit {

  @Input() public byteFlowSettings: ByteFlowSettings;

  constructor(
    private settingsHttpService: SettingsHttpService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
  }

  updateByteFlowSettings(): void {
    const data: any = { 
      byteFlowSettings: this.byteFlowSettings
    };
    this.settingsHttpService.updateByteFlowSettings(data).subscribe(
      () => {
        this.toasterService.success('Byte flow settings updated successfully');
      },
      () => {
        this.toasterService.error('Failed to update byte flow settings');
      }
    );
  }

}
