import { Component, OnInit } from '@angular/core';
import { ErrorTrackerService } from 'src/app/shared/services/error-tracker.service';
import { RequestTrackerService } from 'src/app/shared/services/request-tracker.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-esim-management',
  templateUrl: './esim-management.component.html',
  styleUrls: ['./esim-management.component.scss']
})
export class EsimManagementComponent implements OnInit {

  public failedEsims: any[] = [];
  public fetchedFaileds: boolean = false;

  constructor(private errorTrackerService: ErrorTrackerService,
    private readonly requestTrackerService: RequestTrackerService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getMissingIds();
    });
  }

  public getMissingIds(): void {
    this.failedEsims = [];
    this.errorTrackerService.getEsimsInFault()
      .subscribe((response: any) => {
        this.failedEsims = response.data;
        this.fetchedFaileds = true;
      });
  }

  public isLoading(): boolean {
    return this.requestTrackerService.isLoading();
  }

}
