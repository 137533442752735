<div class="main-wrapper">
    <div class="row">
        <!-- <div class="col">
            <h2>ESim Management</h2>
        </div> -->
        <div class="row">
            <div class="col">
                <h3>eSIMs that failed to get assigned an AddOn:</h3>
            </div>
        </div>
        <div
            class="row"
            style="font-style: italic; margin-top: 20px; text-align: center"
            *ngIf="failedEsims.length === 0 && fetchedFaileds"
        >
            <div class="col">
                No ESim subscriptions have failed to get assigned an AddOn at
                the moment.
            </div>
        </div>
        <div class="row table-container" *ngIf="failedEsims.length > 0">
            <div class="col">
                <table class="table table-light table-hover">
                    <thead>
                        <tr>
                            <th scope="col">ICC ID</th>
                            <th scope="col">UID</th>
                            <th scope="col">Esim Status</th>
                            <th scope="col">Oasis Status</th>
                            <th scope="col">Reprovision Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let esim of failedEsims">
                            <td>{{ esim.iccId }}</td>
                            <td>{{ esim.uid }}</td>
                            <td>
                                {{ esim.esimStatus }}
                            </td>
                            <td>
                                {{ esim.oasisStatus }}
                            </td>
                            <td>
                                {{ esim.reprovisionCount }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="failedEsims.length > 0">
            <div class="col text-right">
                Count: {{ failedEsims.length }}
            </div>
        </div>
    </div>
</div>
