<div
    class="progress"
    role="progressbar"
    style="height: 25px"
    aria-label="Used Megabytes"
    [attr.aria-valuenow]="usedMegabytes"
    aria-valuemin="0"
    [attr.aria-valuemax]="validityMegabytes"
>
    <div
        [ngClass]="{
            data_available: !isDataExhausted,
            data_full: isDataExhausted
        }"
        class="progress-bar overflow-visible text-dark"
        [style.width.%]="(usedMegabytes / validityMegabytes) * 100"
    >
        &nbsp; {{ usedMegabytes }} / {{ validityMegabytes }}
    </div>
</div>
