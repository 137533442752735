<nav class="navbar navbar-light bg-light px-2">
    <form class="form-inline">
        <button [disabled]="isLoading()" type="button" [class.btn-success]="!isLoading()" [class.btn-outline-secondary]="isLoading()" class="btn" (click)="exportToCSV()">Export to CSV</button>
    </form>
  </nav>
<table id="tblMembers" class="table table-striped table-hover table-lg table-responsive">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col" sortable="uid" (sort)="onSort($event)">UID</th>
            <th scope="col" sortable="createdDate" (sort)="onSort($event)">Member Since</th>
            <th scope="col" sortable="lastSignInDate" (sort)="onSort($event)">Last Sign-in</th>
            <th scope="col" sortable="displayName" (sort)="onSort($event)">Display Name</th>
            <th scope="col" sortable="email" (sort)="onSort($event)">Email</th>
            <th scope="col" sortable="phoneNumber" (sort)="onSort($event)">Phone Number</th>
            <th scope="col" sortable="role" (sort)="onSort($event)">Role</th>
            <th scope="col" sortable="iccId" (sort)="onSort($event)">ICC ID</th>
            <th scope="col" sortable="eSimState" (sort)="onSort($event)">eSIM State</th>
            <th scope="col" sortable="planType" (sort)="onSort($event)">Plan Type</th>
            <th scope="col" sortable="currentPlanDataBytesUsed" (sort)="onSort($event)">Current Usage (MB)</th>
            <th scope="col" sortable="totalDataBytesUsed" (sort)="onSort($event)">Total Usage (MB)</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let member of (sortableMembers | async); index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td><a [href]="member.userFirestoreLink" target="_blank">{{ member.uid }}</a></td>
            <td>{{ member.createdDate | date : "MMM d, y, h:mm:ss a" }}</td>
            <td>{{ member.lastSignInDate | date : "MMM d, y, h:mm:ss a" }}</td>
            <td>{{ member.displayName }}</td>
            <td>{{ member.email }}</td>
            <td>{{ member.phoneNumber }}</td>
            <td>{{ member.role }}</td>
            <td><a [href]="member.eSimDashboardLink" target="_blank">{{ member.iccId }}</a></td>
            <td>{{ member.eSimState }}</td>
            <td>{{ member.planType }}</td>
            <td>{{ member.currentPlanDataBytesUsed }}</td>
            <td>{{ member.totalDataBytesUsed }}</td>
        </tr>
    </tbody>
</table>
