<div class="container">
    <div class="row">
        <div class="col">
            <table class="table table-light table-hover table-sm">
                <tbody>
                    <tr *ngFor="let field of userFields">
                        <th scope="row">{{ field.key }}</th>
                        <td>{{ field.value }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
