import { NgModule } from "@angular/core";
import { SortableDirective } from "../directives/sortable.directive";
import { CopyToClipboardDirective } from "../directives/copy-to-clipboard.directive";

@NgModule({
    declarations: [
        SortableDirective,
        CopyToClipboardDirective
    ],
    exports: [
        SortableDirective,
        CopyToClipboardDirective
    ]
})
export class DirectiveModule { }
