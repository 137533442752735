import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { canActivate, customClaims } from "@angular/fire/auth-guard"
import { pipe } from "rxjs";
import { map } from "rxjs/operators";
import { DashboardComponent } from "./dashboard.component";

export const redirectNonAdminToDashboard = () => pipe(
    customClaims,
    map((claim: any) => {
        return claim?.role === "admin" ? true : ["/dashboard"];
    })
);

const routes: Routes = [{
    path: "",
    component: DashboardComponent,
    pathMatch: "full"
}, {
    path: "",
    children: [{
        path: "admin",
        loadChildren: () => import("./modules/admin/admin.module").then(m => m.AdminModule),
        ...canActivate(redirectNonAdminToDashboard)
    }]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
