<div class="row table-container" *ngIf="paymentList?.length > 0">
    <div class="col">
        <table class="table table-light table-hover">
            <thead>
                <tr>
                    <th class="col">Type</th>
                    <th class="col">PSP Reference</th>
                    <th class="col">Country</th>
                    <th class="col">Amount</th>
                    <th class="col">Number of Days</th>
                    <th class="col">Date</th>
                    <th class="col">Success Status</th>
                    <th class="col">Inconsistency?</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of paymentList">
                    <td>
                        <span *ngIf="payment.status === 'Cancellation'">
                            <span
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover focus"
                                data-bs-custom-class="button-tooltip"
                                data-bs-placement="top"
                                data-bs-title="Cancellation"
                            >
                                <i
                                    style="color: yellow"
                                    class="bi bi-backspace-fill"
                                ></i>
                            </span>
                        </span>
                        <span *ngIf="payment.status === 'Refund'">
                            <span
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover focus"
                                data-bs-custom-class="button-tooltip"
                                data-bs-placement="top"
                                data-bs-title="Refund"
                            >
                                <i
                                    style="color: red"
                                    class="bi bi-backspace-fill"
                                ></i>
                            </span>
                        </span>
                        <span *ngIf="payment.status === 'Authorisation'">
                            <span
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover focus"
                                data-bs-custom-class="button-tooltip"
                                data-bs-placement="top"
                                data-bs-title="Authorisation"
                            >
                                <i
                                    style="color: green"
                                    class="bi bi-wallet2"
                                ></i>
                            </span>
                        </span>
                        <span *ngIf="payment.status === 'Fraud'">
                            <span
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover focus"
                                data-bs-custom-class="button-tooltip"
                                data-bs-placement="top"
                                data-bs-title="Fraud"
                            >
                                <i
                                    style="color: orange"
                                    class="bi bi-exclamation-triangle-fill"
                                ></i>
                            </span>
                        </span>
                        <span *ngIf="payment.status === 'ZeroPayment'">
                            <span
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover focus"
                                data-bs-custom-class="button-tooltip"
                                data-bs-placement="top"
                                data-bs-title="Zero Payment"
                            >
                                <i
                                    style="color: blue"
                                    class="bi bi-slash-circle-fill"
                                ></i>
                            </span>
                        </span>
                    </td>
                    <td>{{ payment.pspReference }}</td>
                    <td>{{ payment.paymentReference?.country }}</td>
                    <td>{{ payment.amount }} {{ payment.currency }}</td>
                    <td>{{ payment.paymentReference?.numberOfDays }}</td>
                    <td>{{ payment.eventDate | date : "dd-MM-YY HH:mm OO"}}</td>
                    <td>
                        <span *ngIf="!payment.success">
                            <span
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover focus"
                                data-bs-custom-class="button-tooltip"
                                data-bs-placement="top"
                                data-bs-title="Failure"
                            >
                                <i
                                    style="color: red"
                                    class="bi bi-x-circle-fill"
                                ></i>
                            </span>
                        </span>
                        <span *ngIf="payment.success">
                            <span
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover focus"
                                data-bs-custom-class="button-tooltip"
                                data-bs-placement="top"
                                data-bs-title="Success"
                            >
                                <i
                                    style="color: green"
                                    class="bi bi-check-circle-fill"
                                ></i>
                            </span>
                        </span>
                    </td>
                    <td>
                        <ng-container *ngIf="payment.inconsistency">
                            {{ payment.inconsistency }}
                        </ng-container>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="isLoading()" class="text-center">
    <div class="spinner-border text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div *ngIf="paymentList?.length === 0 && !isLoading()" class="text-center">
    <p>No payment found</p>
</div>
