import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/shared/models/api-response";

@Injectable()
export class SettingsHttpService {

    public constructor(
        private readonly httpClient: HttpClient
    ) { }

    public getByteFlowSettings(): Observable<ApiResponse<any>> {
        return this.httpClient.get<ApiResponse<any>>("/api/backoffice/settings/byte-flow");
    }

    public updateByteFlowSettings(byteFlowSettings: any): Observable<ApiResponse<any>> {
        return this.httpClient.put<ApiResponse<any>>("/api/backoffice/settings/byte-flow", byteFlowSettings);
    }
}
