import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/users/user';
import { UserHttpService } from '../../services/user.http.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import * as bootstrap from "bootstrap";
import { RequestTrackerService } from 'src/app/shared/services/request-tracker.service';

@Component({
  selector: 'app-view-payment-history',
  templateUrl: './view-payment-history.component.html',
  styleUrls: ['./view-payment-history.component.scss']
})
export class ViewPaymentHistoryComponent implements OnInit {
  @Input() private user: User;
  paymentList: any;

  constructor(
    private readonly userHttpService: UserHttpService,
    private readonly toasterService: ToasterService,
    private readonly requestTrackerService: RequestTrackerService
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getPaymentHistory();
    });
  }

  private getPaymentHistory(): void {
    this.userHttpService.getPaymentHistory(this.user.uid).subscribe((response) => {
      console.log("response", response);
      this.paymentList = response.data;
      for(let payment of this.paymentList) {
        let decimalPoint = payment.amount.length;
        let trueAmount = payment.amount.substring(0, decimalPoint-2) + "." + payment.amount.substring(decimalPoint-2);
        payment.amount = trueAmount;
        payment.eventDate = new Date(payment.eventDate._seconds * 1000 + payment.eventDate._nanoseconds / 1000000)
      };

      this.paymentList.sort((a, b) => {
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);
        return dateB.getTime() - dateA.getTime();
      });
      this.initialiseToolTips();
    },
    (error) => {
      this.toasterService.error(`ERROR: ${error.error.message}`);
    });

  }

  private initialiseToolTips(): void {
    setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      })
    });
  }

  public isLoading(): boolean {
    return this.requestTrackerService.isLoading();
  }

}
