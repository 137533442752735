<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container bootstrap snippets bootdeys">
    <div class="col-xs-12 col-sm-9">
        <form class="form-horizontal">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">Promo Code Information</h4>
                </div>
                <div class="panel-body">
                    <div class="form-group">
                        <label>Promo Code</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="text" name="code" [(ngModel)]="promoCode.code" disabled>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Promo Code Name</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="text" name="name" [(ngModel)]="promoCode.name">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Promo Code Plan Type</label>
                        <div class="col-sm-10">
                            <select class="form-control" name="planType" data-style="btn btn-primary btn-sm"
                                data-live-search="true" [(ngModel)]="promoCode.planType" disabled>
                                <option *ngFor="let planType of promoCodePlanTypes" [value]="planType">
                                    {{promoCodePlanTypeMapping[planType]}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Promo Code Discount Type</label>
                        <div class="col-sm-10">
                            <select class="form-control" name="discountType" data-style="btn btn-primary btn-sm"
                                data-live-search="true" [(ngModel)]="promoCode.discountType" disabled>
                                <option *ngFor="let discountType of promoCodeDiscountTypes" [value]="discountType">
                                    {{promoCodeMapping[discountType]}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Promo Code Discount</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="discount"
                                [(ngModel)]="promoCode.discount">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Partner</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="text" name="partnerId"
                                [(ngModel)]="promoCode.partnerId" disabled>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-10 col-sm-offset-2">
                          <div class="checkbox">
                            <label for="checkbox_1">Allowed Only Once</label>
                            <input type="checkbox" id="limitedToOneUsePerUser" name="limitedToOneUsePerUser" [(ngModel)]="promoCode.limitedToOneUsePerUser" disabled>
                          </div>
                        </div>
                      </div>
                    <div class="form-group">
                        <label>Maximum Promo Code Users</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="maximumPromoCodeUsers"
                                [(ngModel)]="promoCode.maximumPromoCodeUsers">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Remaining Promo Code Users</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="remainingPromoCodeUsers"
                                [(ngModel)]="promoCode.remainingPromoCodeUsers">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Creation Date</label>
                        <div class="col-sm-10">
                            <input class="form-control"  type="date" name="uiCreationDate"
                                [(ngModel)]="promoCode.uiCreationDate" disabled>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>End Date</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="date" name="uiEndDate" [(ngModel)]="promoCode.uiEndDate">
                        </div>
                    </div>
                    <div class="col-3 text-end">
                        <button type="button" class="btn btn-primary" aria-expanded="false"
                            (click)="editPromoCode()">
                            <i class="bi bi-pencil-fill"></i>
                            &nbsp;&nbsp;Edit Promo Code
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>