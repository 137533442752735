import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/shared/models/api-response";

@Injectable()
export class PromoCodeHttpService {

    public constructor(
        private readonly httpClient: HttpClient
    ) { }

    public getPromoCodes(): Observable<ApiResponse<any[]>> {
        return this.httpClient.get<ApiResponse<any[]>>("/api/backoffice/promo-codes");
    }

    public createPromoCode(promoCodeRequest: any): Observable<ApiResponse<any[]>> {
        return this.httpClient.post<ApiResponse<any>>("/api/backoffice/promo-codes", promoCodeRequest);
    }

    public updatePromoCode(promoCodeRequest: any): Observable<ApiResponse<any>> {
        return this.httpClient.put<ApiResponse<any>>(`api/backoffice/promo-codes/${promoCodeRequest.code}`, promoCodeRequest);
    }

    public deletePromoCode(code: any): Observable<ApiResponse<any>> {
        return this.httpClient.delete<ApiResponse<any>>(`api/backoffice/promo-codes/${code}`);
    }
}
