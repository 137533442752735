export const environment = {
    production: true,
    useEmulators: false,
    firebase: {
        apiKey: "AIzaSyDckgdY2tr3cZQgGkG9_bVWTruaaR8MS6g",
        authDomain: "firsty-test.firebaseapp.com",
        projectId: "firsty-test",
        storageBucket: "firsty-test.appspot.com",
        messagingSenderId: "411779711619",
        appId: "1:411779711619:web:37934b1b2f3928fda851b6"
    },
    isTest: true
};
