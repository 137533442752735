import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/users/user';
import { DateParser } from 'src/app/shared/utils/date-parser';

@Component({
  selector: 'app-manage-user-esim',
  templateUrl: './manage-user-esim.component.html',
  styleUrls: ['./manage-user-esim.component.scss']
})
export class ManageUserEsimComponent implements OnInit {
  @Input() private user: User;
  public userFields: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.processEsimInfo();
  }

  processEsimInfo(): void {
    if (this.user.esim) {
      const esimFields = [
        { key: "Assign Date", value: DateParser.getDate(this.user.esim.assignDate) },
        { key: "Activation Code", value: this.user.esim.activationCode },
        { key: "Activation Date", value: DateParser.getDate(this.user.esim.activationDate) ?? "N/A" },
        { key: "Icc Id", value: this.user.esim.iccId },
        { key: "Status", value: this.user.esim.status },
        { key: "Provider Name", value: this.user.esim.providerName },
        { key: "Reprovision Count", value: this.user.esim.reprovisionCount ? this.user.esim.reprovisionCount.toString() : "0" },
        { key: "Oasis Status", value: this.user.esim.oasisStatus ?? "N/A" },
        { key: "Has Usage Overall", value: this.user.esim.hasUsageOverall ?? "N/A" },
        { key: "Has Usage Last 15 Minutes", value: this.user.esim.hasUsageLast15Minutes ?? "N/A" },
        { key: "Last 15 Minutes Usage Date", value: DateParser.getDate(this.user.esim.hasUsageLast15MinutesDate) ?? "N/A" },
      ];

      this.userFields.push(...esimFields);

      if (this.user.esim.reprovisionCount) {
        esimFields.push({ key: "Reprovision Count", value: this.user.esim.reprovisionCount == null ? "0" : this.user.esim.reprovisionCount.toString() });
      }
    }

    if (this.user.device) {
      const deviceFields = [
        { key: "Device Id", value: this.user.device.deviceId },
        { key: "Device Creation Date", value: DateParser.getDate(this.user.device.deviceCreationDate) },
        { key: "Device Update Date", value: DateParser.getDate(this.user.device.deviceUpdateDate) },
        { key: "Fcm Token", value: this.user.device.fcmToken },
      ];

      this.userFields.push(...deviceFields);
    }

    let deviceCount: number = 0;
    if (this.user.devices) {
      for (const key in this.user.devices) {
        deviceCount++;
      }
    }

    this.userFields.push({ key: "Old Device Field Count", value: deviceCount });

  }
}
