import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class RequestTrackerService {
    
    private requestCount: number = 0;

    public startRequest(): void {
        this.requestCount++;
    }

    public endRequest(): void {
        this.requestCount--;
    }

    public isLoading(): boolean {
        return this.requestCount > 0;
    }
}
