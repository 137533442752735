<div class="container text-center">
    <div class="row top-section">
        <div class="col main-wrapper">
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Esim left available for assignment"
                    >
                        <i class="bi bi-sim-fill esim-card"></i>
                    </span>
                    <span class="text-element">{{ esimCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Active Advertisement Subscriptions"
                    >
                    <i class="bi bi-database-fill ad-card"></i>
                </span>
                    <span class="text-element">{{ advertisementCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Active Byte Subscriptions"
                    >
                    <i class="bi bi-database-fill byte-card"></i>
                </span>
                    <span class="text-element">{{ byteFlowCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Active Comfort Plus Subscriptions"
                    >
                    <i class="bi bi-database-fill premium-card"></i>
                </span>
                    <span class="text-element">{{ premiumCount }}</span>
                </div>
            </div>
            <div class="card card-element">
                <div class="card-body body-element">
                    <span
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover focus"
                        data-bs-custom-class="button-tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Active First Class Subscriptions"
                    >
                    <i class="bi bi-database-fill ultra-card"></i>
                </span>
                    <span class="text-element">{{ ultraCount }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row bottom-section">
        <div class="col"></div>
    </div>
</div>
