<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container bootstrap snippets bootdeys">
    <div class="col-xs-12 col-sm-9">
        <form class="form-horizontal">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h4 class="panel-title">Byte Flow Settings</h4>
                </div>
                <div class="panel-body">
                    <div class="form-group">
                        <label>Megabytes after premium byte unlock</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="megabytesAtStart" [(ngModel)]="byteFlowSettings.megabytesAtStart" >
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Megabytes For Byte</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="megabytesPerTab" [(ngModel)]="byteFlowSettings.megabytesPerTab">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Megabytes for user per invitation</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="userMbsPerInvitation"
                                [(ngModel)]="byteFlowSettings.userMbsPerInvitation">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Megabytes for friends per invitation</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="friendMbsPerInvitation"
                                [(ngModel)]="byteFlowSettings.friendMbsPerInvitation" >
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-10 col-sm-offset-2">
                          <div class="checkbox">
                            <label for="checkbox_1">Referring user gets megabytes when friend purchases premium or ultra</label>
                            <br>
                            <input type="checkbox" id="friendMbsForFirstPurchaseEnabled" name="friendMbsForFirstPurchaseEnabled" [(ngModel)]="byteFlowSettings.friendMbsForFirstPurchaseEnabled" >
                          </div>
                        </div>
                      </div>
                    <div class="form-group">
                        <label>Megabytes to give referred user on their first puchase of premium</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="friendMbsForFirstPremiumPurchase"
                                [(ngModel)]="byteFlowSettings.friendMbsForFirstPremiumPurchase">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Megabytes to give referred user on their first puchase of ultra</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="friendMbsForFirstUltraPurchase"
                                [(ngModel)]="byteFlowSettings.friendMbsForFirstUltraPurchase">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>User maximum friend count</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="friendInvitationLimit"
                                [(ngModel)]="byteFlowSettings.friendInvitationLimit">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Byte plan activation megabyte limit</label>
                        <div class="col-sm-10">
                            <input class="form-control" type="number" name="bytePlanActivationLimit"
                                [(ngModel)]="byteFlowSettings.bytePlanActivationLimit">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-10 col-sm-offset-2">
                          <div class="checkbox">
                            <label for="checkbox_1">Byte flow is allowed for everyone</label>
                            <br>
                            <input type="checkbox" id="byteFlowAllowedForAll" name="byteFlowAllowedForAll" [(ngModel)]="byteFlowSettings.byteFlowAllowedForAll" >
                          </div>
                        </div>
                      </div>
                    <div class="col-3 text-end">
                        <button type="button" class="btn btn-primary" aria-expanded="false"
                            (click)="updateByteFlowSettings()">
                            <i class="bi bi-pencil-fill"></i>
                            &nbsp;&nbsp;Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>