import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() public usedMegabytes: number;
  @Input() public validityMegabytes: number;
  @Input() public isDataExhausted: boolean;


  constructor() { }

  ngOnInit(): void {
  }

}
