<div class="col-3 text-end">
    <button type="button" class="btn btn-quaternary" aria-expanded="false" data-bs-toggle="modal"
        data-bs-target="#byteFlowSettingsModal" (click)="
        manageByteFlowSettings(
                            modalOptions.BYTE_FLOW_SETTINGS
                        )
                    ">
        <span data-bs-toggle="tooltip" data-bs-trigger="hover focus" data-bs-custom-class="button-tooltip"
            data-bs-placement="top" data-bs-title="Edit byte flow settings">
            <i class="bi bi-pencil-fill"></i>
            &nbsp;&nbsp;Edit Byte Flow Settings
        </span>
    </button>
</div>


<div class="modal fade" id="byteFlowSettingsModal" tabindex="-1" aria- labelledby="Edit byte flow settings" aria-hidden="true"
    data-bs-backdrop="static">
    <div class="modal-dialog wide-modal">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <ng-container *ngIf="selectedModal === modalOptions.BYTE_FLOW_SETTINGS">
                        Edit Byte Flow Settings
                    </ng-container>
                </h2>
                <button #closebutton type="button" class="btn-close" aria-label="Close"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="selectedModal === modalOptions.BYTE_FLOW_SETTINGS && showModals">
                    <app-edit-byte-flow-settings [byteFlowSettings]="byteFlowSettings"></app-edit-byte-flow-settings>
                </ng-container>
            </div>
        </div>
    </div>
</div>