<nav class="navbar navbar-light bg-light px-2">
    <div class="form-group row">
        <label class="col-md-4 col-form-label">Response: </label>
        <div class="col-md-8">
            <input type="text" readonly class="form-control-plaintext" id="txtResponse" [value]="response">
        </div>
    </div>
</nav>
<form class="col-md-10 offset-md-1">
    <div class="form-group row mt-3">
        <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" multiple="false" />
    </div>
    <div class="form-group row">
        <label class="col-md-2 col-form-label">Upload ESIM Batch</label>
        <div class="col-md-1 offset-md-9">
            <button id="btnSaveESIMs" [disabled]="isLoading()" type="button" [class.btn-primary]="!isLoading()"
                [class.btn-outline-secondary]="isLoading()" class="btn w-100"
                (click)="openFileInput(FileUploadPurpose.ESIMS)">Go</button>
        </div>
    </div>
    <div class="form-group row mt-3">
        <label class="col-md-2 col-form-label">Process ESIM Batch</label>
        <div class="col-md-4">
            <input id="txtFileUrl" #txtFileUrl type="text" class="form-control" placeholder="*File URL" />
        </div>
        <div class="col-md-1 offset-md-5">
            <button id="btnProcessESIMs" [disabled]="isLoading()" type="button" [class.btn-primary]="!isLoading()"
                [class.btn-outline-secondary]="isLoading()" class="btn w-100" (click)="processESIMs">Go</button>
        </div>
    </div>
    <div class="form-group row mt-3">
        <label class="col-md-2 col-form-label">Import Plans</label>
        <div class="col-md-1 offset-md-9">
            <button id="btnImportPlans" [disabled]="isLoading()" type="button" [class.btn-primary]="!isLoading()"
                [class.btn-outline-secondary]="isLoading()" class="btn w-100"
                (click)="openFileInput(FileUploadPurpose.PLANS)">Go</button>
        </div>
    </div>
    <div class="form-group row mt-3">
        <label class="col-md-2 col-form-label">Welcome flow</label>
        <div class="col-md-2">
            <input id="txtUid" type="text" class="form-control" placeholder="*UID" />
        </div>
        <div class="col-md-2">
            <input id="txtDeviceId" type="text" class="form-control" placeholder="*Device ID" />
        </div>
        <div class="col-md-2">
            <input id="txtFcmToken" type="text" class="form-control" placeholder="*FCM Token" />
        </div>
        <div class="col-md-1">
            <input id="txtPhoneNumber" type="text" class="form-control" placeholder="*Phone" />
        </div>
        <div class="col-md-1">
            <input id="txtCurrentCountry" type="text" class="form-control" placeholder="Country" />
        </div>
        <div class="col-md-1">
            <input id="txtUserName" type="text" class="form-control" placeholder="Name" />
        </div>
        <div class="col-md-1">
            <button [disabled]="isLoading()" type="button" [class.btn-primary]="!isLoading()"
                [class.btn-outline-secondary]="isLoading()" class="btn w-100" (click)="welcome()">Go</button>
        </div>
    </div>
    <div class="form-group row mt-3">
        <label class="col-md-2 col-form-label">Create new Device</label>
        <div class="col-md-2">
            <input id="txtUid" type="text" class="form-control" placeholder="*UID" />
        </div>
        <div class="col-md-2">
            <input id="txtDeviceId" type="text" class="form-control" placeholder="*Device ID" />
        </div>
        <div class="col-md-2">
            <input id="txtFcmToken" type="text" class="form-control" placeholder="*FCM Token" />
        </div>
        <div class="col-md-2">
            <input id="txtIccId" type="text" class="form-control" placeholder="*ICCID" />
        </div>
        <div class="col-md-1 offset-md-1">
            <button [disabled]="isLoading()" type="button" [class.btn-primary]="!isLoading()"
                [class.btn-outline-secondary]="isLoading()" class="btn w-100" (click)="createDevice()">Go</button>
        </div>
    </div>
    <div class="form-group row mt-3">
        <label class="col-md-2 col-form-label">Update User Name</label>
        <div class="col-md-2">
            <input id="txtUid" type="text" class="form-control" placeholder="*UID" />
        </div>
        <div class="col-md-2">
            <input id="txtUserName" type="text" class="form-control" placeholder="*Name" />
        </div>
        <div class="col-md-1 offset-md-5">
            <button [disabled]="isLoading()" type="button" [class.btn-primary]="!isLoading()"
                [class.btn-outline-secondary]="isLoading()" class="btn w-100" (click)="editUserName()">Go</button>
        </div>
    </div>
    <div class="form-group row mt-3">
        <label class="col-md-2 col-form-label">Delete User</label>
        <div class="col-md-2">
            <input id="txtUid" type="text" class="form-control" placeholder="*UID" />
        </div>
        <div class="col-md-1 offset-md-7">
            <button [disabled]="isLoading()" type="button" [class.btn-danger]="!isLoading()"
                [class.btn-outline-secondary]="isLoading()" class="btn w-100" (click)="deleteUser()">Go</button>
        </div>
    </div>
    <hr />
    <div class="form-group row mt-3">
        <label class="col-md-2 col-form-label">Simulate Notification</label>
        <div class="col-md-4">
            <select #sNotifications class="form-select" (change)="onNotificationSelected(sNotifications.value)">
                <option default>Select Notification Type...</option>
                <option value="LOCATIONUPDATE_FIRSTEVENT">ESIM Activation</option>
                <option value="CHANGEPRODUCTSTATE_SUCCESS">Subscription Status Change</option>
                <option value="PURCHASEADDON_SUCCESS">Purchase AddOn Success</option>
                <option value="LOWBALANCE_DATA">Low Balance - Data</option>
                <option value="EMPTYBALANCE_DATA">Empty Balance - Data</option>
            </select>
        </div>
        <div class="col-md-1 offset-md-5">
            <button [disabled]="isLoading()" type="button" [class.btn-success]="!isLoading()"
                [class.btn-outline-secondary]="isLoading()" class="btn w-100" (click)="deleteUser()">Go</button>
        </div>
    </div>
</form>
