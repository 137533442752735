import { Component, OnInit } from '@angular/core';
//import { PromoCodeHttpService } from '../../services/promo-code.http.service';
import { RequestTrackerService } from 'src/app/shared/services/request-tracker.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { PromoCode } from '../../models/promo-codes/promo-code';
import { PromoCodeManagementModal } from '../../enums/promo-code-management-modal';
import * as bootstrap from "bootstrap";
import { PromoCodeHttpService } from '../../services/promo-code.http.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.scss']
})
export class PromoCodesComponent implements OnInit {

  listPromoCodeResponse: PromoCode[] = [];

  showModals: boolean = false;
  modalOptions = PromoCodeManagementModal;
  selectedModal: PromoCodeManagementModal;
  promoCode: PromoCode;

  constructor(
    private readonly requestTrackerService: RequestTrackerService,
    private readonly promoCodeHttpService: PromoCodeHttpService,
    private readonly toasterService: ToasterService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getPromoCodes();
    });
  }

  private initialiseToolTips(): void {
    setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      })
    });
  }

  public getPromoCodes(): void {
    this.promoCodeHttpService.getPromoCodes().subscribe(
      (response) => {
        if (!response.data) {
          this.noPromoCodeFound();
          return;
        }

        this.listPromoCodeResponse = response.data;

        if (this.listPromoCodeResponse.length === 0) {
          this.noPromoCodeFound();
        } else {
          for (const promoCode of this.listPromoCodeResponse) {
            promoCode.creationDate = new Date(promoCode.creationDate);
            promoCode.uiCreationDate = this.datePipe.transform(promoCode.creationDate, 'yyyy-MM-dd');
            promoCode.endDate = new Date(promoCode.endDate);
            promoCode.uiEndDate = this.datePipe.transform(promoCode.endDate, 'yyyy-MM-dd');
          }
        }
      },
      (error) => {
        this.toasterService.error(`ERROR: ${error.error.message}`);
      }
    );
  }

  public managePromoCode(modal: PromoCodeManagementModal, promoCode?: PromoCode): void {
    console.log(promoCode);
    this.showModals = true;
    const tooltipElements = Array.from(document.querySelectorAll('.bs-tooltip-auto'));
    for (const item of tooltipElements) {
      item.remove();
    }
    if (promoCode) {
      this.promoCode = promoCode;
    }
    this.selectedModal = modal;
    this.initialiseToolTips();
  }

  public deletePromoCode(code: string): void {
    this.promoCodeHttpService.deletePromoCode(code).subscribe(
      () => {
        this.toasterService.success("Promo code deleted successfully");
        this.listPromoCodeResponse = this.listPromoCodeResponse.filter((promo) => promo.code !== code);
      },
      () => {
        this.toasterService.error("Failed to delete promo code");
      }
    );
  }

  public noPromoCodeFound(): void {
    this.toasterService.warn(
      "No promo code found"
    );
  }

  public isLoading(): boolean {
    return this.requestTrackerService.isLoading();
  }

}
