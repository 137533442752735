import { NgModule } from "@angular/core";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { Auth, provideAuth, getAuth, connectAuthEmulator } from "@angular/fire/auth";
import { Functions, getFunctions, provideFunctions, connectFunctionsEmulator } from "@angular/fire/functions";
import { BrowserModule } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FirebaseIdTokenInterceptor } from "src/app/shared/interceptors/firebase-id-token.interceptor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RequestLifecycleInterceptor } from "./shared/interceptors/request-lifecycle.interceptor";
import { DatePipe } from "@angular/common";
import { DirectiveModule } from "./shared/modules/directive.module";


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            const auth: Auth = getAuth();

            if (environment.useEmulators) {
                connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
            }

            return auth;
        }),
        provideFunctions(() => {
            const functions: Functions = getFunctions();

            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, "http://localhost", 5001);
            }

            return functions;
        }),
        HttpClientModule,
        BrowserModule,
        FormsModule,
        NgbModule,
        AppRoutingModule,
        DashboardModule,
        DirectiveModule
    ],
    providers: [
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: FirebaseIdTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestLifecycleInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
