<div>
    <button type="button" class="btn btn-quaternary" aria-expanded="false" data-bs-toggle="modal"
        data-bs-target="#promoCodeCreateModal" (click)="
                    managePromoCode(
                            modalOptions.CREATE_PROMO_CODE
                        )
                    ">
        <span data-bs-toggle="tooltip" data-bs-trigger="hover focus" data-bs-custom-class="button-tooltip"
            data-bs-placement="top" data-bs-title="Create promo code">
            <i class="bi bi-plus-circle"></i>
        </span>
    </button>
    <table class="table">
        <thead>
            <tr>
                <th>Code</th>
                <th>Name</th>
                <th>Discount Type</th>
                <th>Discount Amount</th>
                <th>Partner</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let promoCode of listPromoCodeResponse">
                <td>{{ promoCode.code }}</td>
                <td>{{ promoCode.name }}</td>
                <td>{{ promoCode.discountType }}</td>
                <td>{{ promoCode.discount }}</td>
                <td>{{ promoCode.partnerId }}</td>
                <td><button type="button" class="btn btn-quaternary" aria-expanded="false" data-bs-toggle="modal"
                        data-bs-target="#promoCodeEditModal" (click)="
                    managePromoCode(
                            modalOptions.EDIT_PROMO_CODE,
                            promoCode
                        )
                    ">
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover focus"
                            data-bs-custom-class="button-tooltip" data-bs-placement="top"
                            data-bs-title="Edit promo code">
                            <i class="bi bi-pencil-fill"></i>
                        </span>
                    </button></td>

                <td><button type="button" class="btn btn-danger" aria-expanded="false" (click)="
                    deletePromoCode(
                            promoCode.code
                        )
                    ">
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover focus"
                            data-bs-custom-class="button-tooltip" data-bs-placement="top"
                            data-bs-title="Delete promo code">
                            <i class="bi bi-trash-fill"></i>
                        </span>
                    </button></td>
            </tr>
        </tbody>
    </table>


    <div class="modal fade" id="promoCodeEditModal" tabindex="-1" aria- labelledby="Edit Subscription"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog wide-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title" id="exampleModalLabel">
                        <ng-container *ngIf="selectedModal === modalOptions.EDIT_PROMO_CODE">
                            Edit Promo Code
                        </ng-container>
                    </h2>
                    <button #closebutton type="button" class="btn-close" aria-label="Close"
                        data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <ng-container *ngIf="selectedModal === modalOptions.EDIT_PROMO_CODE && showModals">
                        <app-edit-promo-code [promoCode]="promoCode"></app-edit-promo-code>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="promoCodeCreateModal" tabindex="-1" aria- labelledby="Create Subscription"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog wide-modal">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <ng-container *ngIf="selectedModal === modalOptions.CREATE_PROMO_CODE">
                        Create Promo Code
                    </ng-container>
                </h2>
                <button #closebutton type="button" class="btn-close" aria-label="Close"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="selectedModal === modalOptions.CREATE_PROMO_CODE && showModals">
                    <app-create-promo-code></app-create-promo-code>
                </ng-container>
            </div>
        </div>
    </div>
</div>