import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { map, mergeMap, scan, startWith, switchMap, toArray } from "rxjs/operators";
import { BehaviorSubject, Observable, from, of } from "rxjs";
import { SortableDirective } from "src/app/shared/directives/sortable.directive";
import { SortEvent } from "src/app/shared/events/sort.event";
import { RequestTrackerService } from "src/app/shared/services/request-tracker.service";
import * as Papa from "papaparse";
import { Payment } from "../../models/payment";
import { PaymentService } from "../../services/payment.http.service";

const defaultValue = (v: string | Date): string | Date => !!v ? v : ((v instanceof Date) ? new Date(0) : "");
const compare = (v1: string | Date, v2: string | Date): number => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
    selector: "payments",
    templateUrl: "./payments.component.html",
    styleUrls: ["./payments.component.scss"]
})
export class PaymentsComponent implements OnInit {

    @ViewChildren(SortableDirective)
    private tableHeaders: QueryList<SortableDirective<Payment>>;

    private payments: Observable<Payment[]>;

    private _sortablePayments: Observable<Payment[]>;

    public get sortablePayments(): Observable<Payment[]> {
        return this._sortablePayments;
    }

    private readonly paymentsSubject: BehaviorSubject<Payment[]> = new BehaviorSubject<Payment[]>([]);

    public constructor(
        private readonly paymentService: PaymentService,
        private readonly requestTrackerService: RequestTrackerService
    ) { }

    public async ngOnInit(): Promise<void> {
        const listAllPaymentsResponse: Observable<Payment> = this.paymentService.listAuthorizedPaymentsOfAllUsers().pipe(mergeMap(listPaymentsResponse => from(listPaymentsResponse.data)));

        this.payments = listAllPaymentsResponse.pipe(
            scan((acc: Payment[], curr: Payment) => {
                const existingIndex = acc.findIndex((payment: Payment) => payment.pspReference === curr.pspReference);

                if (existingIndex !== -1) {
                    acc[existingIndex] = curr;
                } else {
                    acc.push(curr);
                }

                return acc;
            }, [])
        );

        this.payments.subscribe(payments => {
            this.paymentsSubject.next(payments);
        });

        this.updateSortablePayments();
    }

    public isLoading(): boolean {
        return this.requestTrackerService.isLoading();
    }

    public exportToCSV(): void {
        this.sortablePayments.subscribe((payments: Payment[]) => {
            if (payments.length > 0) {
                const csvData = Papa.unparse(payments);

                const blob: Blob = new Blob([csvData], { type: "text/csv" });
                const url: string = window.URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement("a");
                const date: Date = new Date();

                a.href = url;
                a.download = `payments_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.csv`;

                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public onSort(sortEvent: SortEvent<Payment>): void {
        this.tableHeaders.forEach(header => {
            if (header.sortable !== sortEvent.column) {
                header.direction = "";
            }
        });

        this.updateSortablePayments(sortEvent);
    }

    private sortPayments(payments: Payment[], sortEvent: SortEvent<Payment>): Payment[] {
        return payments.sort((payment1: Payment, payment2: Payment) => {
            const res = compare(defaultValue(payment1[sortEvent.column]), defaultValue(payment2[sortEvent.column]));
            return sortEvent.direction === "asc" ? res : -res;
        });
    }

    private updateSortablePayments(sortEvent: SortEvent<Payment> = null): void {
        this._sortablePayments = this.paymentsSubject.pipe(
            startWith([]),
            mergeMap((payments: Payment[]) => {
                if (!sortEvent || sortEvent.direction === "" || sortEvent.column === "") {
                    return of(payments); // Return unsorted payments if no sorting is applied
                } else {
                    return of(this.sortPayments([...payments], sortEvent));
                }
            }),
            scan((acc, val) => val, [] as Payment[]) // Scan to accumulate the latest sorted members
        );
    }

    public isNaN(value: number): boolean {
        return Number.isNaN(value);
    }
}
