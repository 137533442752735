<div class="container text-center">
    <div class="row justify-content-between">
        <div class="col-5">
            <div class="form-floating">
                <input
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    #inputField
                    [(ngModel)]="searchInput"
                />
                <label for="floatingInput">{{ selectedSearchOption }}</label>
            </div>
        </div>
        <div class="col-5">
            <select
                #searchType
                class="form-select search"
                [(ngModel)]="selectedSearchOption"
            >
                <option value="Email" default>Search by E-mail Address</option>
                <option value="Phone">Search by Phone Number</option>
                <option value="ICCID">Search by ICC ID</option>
                <option value="UID">Search by UID</option>
            </select>
        </div>
        <div class="col-2 text-end">
            <button
                [disabled]="isLoading()"
                type="button"
                class="btn btn-info search"
                (click)="getUserUid()"
            >
                <i class="bi bi-search"></i>
                &nbsp;&nbsp;Search For User
            </button>
        </div>
    </div>
    <div class="row table-container" *ngIf="listUsersResponse.length > 0">
        <div class="col">
            <table class="table table-light table-hover">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Uid</th>
                        <th scope="col">ICC ID</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of listUsersResponse">
                        <td>
                            <div class="row" *ngIf="user.isBlocked">
                                <div class="col">
                                    <i
                                        class="bi bi-ban"
                                        style="color: #bd5059"
                                    ></i>
                                </div>
                            </div>
                        </td>
                        <td>{{ user.userEmail }}</td>
                        <td>{{ user.userPhoneNumber }}</td>
                        <td>{{ user.uid }}</td>
                        <td>
                            <ng-container *ngIf="user.esim">
                                {{ user.esim.iccId }}
                            </ng-container>
                        </td>
                        <td>
                            <div class="row">
                                <div class="col">
                                    <div
                                        class="btn-group"
                                        role="group"
                                        aria-label="Basic example"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            aria-expanded="false"
                                            data-bs-toggle="modal"
                                            data-bs-target="#userManagementModal"
                                            (click)="
                                                manageUser(
                                                    user,
                                                    modalOptions.MANAGE_USER_DETAILS
                                                )
                                            "
                                        >
                                            <span
                                                data-bs-toggle="tooltip"
                                                data-bs-trigger="hover focus"
                                                data-bs-custom-class="button-tooltip"
                                                data-bs-placement="top"
                                                data-bs-title="Display and manage user's details"
                                            >
                                                <i
                                                    class="bi bi-person-circle"
                                                ></i>
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-warning add"
                                            aria-expanded="false"
                                            data-bs-toggle="modal"
                                            data-bs-target="#userManagementModal"
                                            (click)="
                                                manageUser(
                                                    user,
                                                    modalOptions.MANAGE_SUBSCRIPTIONS
                                                )
                                            "
                                        >
                                            <span
                                                data-bs-toggle="tooltip"
                                                data-bs-trigger="hover focus"
                                                data-bs-custom-class="button-tooltip"
                                                data-bs-placement="top"
                                                data-bs-title="Display and manage user's subscription"
                                            >
                                                <i
                                                    class="bi bi-database-fill"
                                                ></i>
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-secondary add"
                                            aria-expanded="false"
                                            data-bs-toggle="modal"
                                            data-bs-target="#userManagementModal"
                                            (click)="
                                                manageUser(
                                                    user,
                                                    modalOptions.MANAGE_USER_ESIMS
                                                )
                                            "
                                        >
                                            <span
                                                data-bs-toggle="tooltip"
                                                data-bs-trigger="hover focus"
                                                data-bs-custom-class="button-tooltip"
                                                data-bs-placement="top"
                                                data-bs-title="Display and manage user's ESIM"
                                            >
                                                <i class="bi bi-sim-fill"></i>
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-quaternary add"
                                            aria-expanded="false"
                                            data-bs-toggle="modal"
                                            data-bs-target="#userManagementModal"
                                            (click)="
                                                manageUser(
                                                    user,
                                                    modalOptions.VIEW_PAYMENT_HISTORY
                                                )
                                            "
                                        >
                                            <span
                                                data-bs-toggle="tooltip"
                                                data-bs-trigger="hover focus"
                                                data-bs-custom-class="button-tooltip"
                                                data-bs-placement="top"
                                                data-bs-title="Display user's payment history"
                                            >
                                                <i class="bi bi-sim-fill"></i>
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-tertiary"
                                            aria-expanded="false"
                                            (click)="unblockUser(user.uid)"
                                            *ngIf="user.isBlocked"
                                        >
                                            <span
                                                data-bs-toggle="tooltip"
                                                data-bs-trigger="hover focus"
                                                data-bs-custom-class="button-tooltip"
                                                data-bs-placement="top"
                                                data-bs-title="Unblock this user"
                                            >
                                                <i
                                                    class="bi bi-hand-thumbs-up-fill"
                                                ></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div
    class="modal fade"
    id="userManagementModal"
    tabindex="-1"
    aria-
    labelledby="Add Subscription"
    aria-hidden="true"
    data-bs-backdrop="static"
>
    <div class="modal-dialog wide-modal">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">
                    <ng-container
                        *ngIf="
                            selectedModal === modalOptions.MANAGE_USER_DETAILS
                        "
                    >
                        User Details
                    </ng-container>
                    <ng-container
                        *ngIf="
                            selectedModal === modalOptions.MANAGE_SUBSCRIPTIONS
                        "
                    >
                        User Subscriptions
                    </ng-container>
                    <ng-container
                        *ngIf="selectedModal === modalOptions.MANAGE_USER_ESIMS"
                    >
                        User ESIM
                    </ng-container>
                    <ng-container
                        *ngIf="
                            selectedModal === modalOptions.VIEW_PAYMENT_HISTORY
                        "
                    >
                        User Payment History
                    </ng-container>
                </h2>
                <button
                    #closebutton
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                ></button>
            </div>
            <div class="modal-body">
                <ng-container
                    *ngIf="
                        selectedModal === modalOptions.MANAGE_USER_DETAILS &&
                        showModals
                    "
                >
                    <app-manage-user-details
                        [user]="user"
                    ></app-manage-user-details>
                </ng-container>
                <ng-container
                    *ngIf="
                        selectedModal === modalOptions.MANAGE_SUBSCRIPTIONS &&
                        showModals
                    "
                >
                    <app-manage-subscriptions
                        #mgmtSubs
                        [user]="user"
                    ></app-manage-subscriptions>
                </ng-container>
                <ng-container
                    *ngIf="
                        selectedModal === modalOptions.MANAGE_USER_ESIMS &&
                        showModals
                    "
                >
                    <app-manage-user-esim [user]="user"></app-manage-user-esim>
                </ng-container>
                <ng-container
                    *ngIf="
                        selectedModal === modalOptions.VIEW_PAYMENT_HISTORY &&
                        showModals
                    "
                >
                    <app-view-payment-history
                        [user]="user"
                    ></app-view-payment-history>
                </ng-container>
            </div>
            <div class="modal-footer">
                <div class="col">
                    <ng-container
                        *ngIf="
                            selectedModal === modalOptions.MANAGE_USER_DETAILS
                        "
                    >
                        <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button type="button" class="btn btn-warning">
                                <span
                                    data-bs-toggle="tooltip"
                                    data-bs-custom-class="button-tooltip"
                                    data-bs-placement="top"
                                    (click)="deleteUserData()"
                                    data-bs-title="Delete the user's account."
                                >
                                    <i class="bi bi-person-x"></i>
                                </span>
                            </button>
                            <button type="button" class="btn btn-danger">
                                <span
                                    data-bs-toggle="tooltip"
                                    data-bs-custom-class="button-tooltip"
                                    data-bs-placement="top"
                                    (click)="deleteUserDataFully()"
                                    data-bs-title="Completely annihilate this user's account, their subscription history, existence."
                                >
                                    <i class="bi bi-fire"></i>
                                </span>
                            </button>
                            <button type="button" class="btn btn-tertiary">
                                <span
                                    data-bs-toggle="tooltip"
                                    data-bs-custom-class="button-tooltip"
                                    data-bs-placement="top"
                                    (click)="blockUser()"
                                    data-bs-title="Block user."
                                >
                                    <i class="bi bi-ban"></i>
                                </span>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="
                            selectedModal === modalOptions.MANAGE_SUBSCRIPTIONS
                        "
                    >
                        <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                type="button"
                                class="btn btn-warning"
                                [disabled]="isLoading() || !user.esim"
                            >
                                <span
                                    data-bs-toggle="tooltip"
                                    data-bs-custom-class="button-tooltip"
                                    data-bs-placement="top"
                                    (click)="deleteUserSubscriptionHistory()"
                                    data-bs-title="Clean this user's subscription history"
                                >
                                    <i class="bi bi-clock-history"></i>
                                </span>
                            </button>
                            <button
                                type="button"
                                class="btn btn-danger"
                                aria-expanded="false"
                                (click)="deleteAllSubscriptions()"
                                [disabled]="isLoading() || !user.esim"
                            >
                                <span
                                    data-bs-toggle="tooltip"
                                    data-bs-custom-class="button-tooltip"
                                    data-bs-placement="bottom"
                                    data-bs-title="End all active subscriptions of the user"
                                >
                                    <i class="bi bi-ban"></i>
                                </span>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="selectedModal === modalOptions.MANAGE_USER_ESIMS"
                    >
                        <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                type="button"
                                class="btn btn-warning"
                                (click)="reuseEsim()"
                                [disabled]="isLoading() || !user.esim"
                            >
                                <span
                                    data-bs-toggle="tooltip"
                                    data-bs-custom-class="button-tooltip"
                                    data-bs-placement="top"
                                    data-bs-title="Reuse the old esim"
                                >
                                    <i class="bi bi-arrow-clockwise"></i>
                                </span>
                            </button>
                            <button
                                type="button"
                                class="btn btn-danger"
                                (click)="newEsim()"
                                [disabled]="isLoading()"
                            >
                                <span
                                    data-bs-toggle="tooltip"
                                    data-bs-custom-class="button-tooltip"
                                    data-bs-placement="top"
                                    data-bs-title="Unassign the E-SIM from the user"
                                >
                                    <i class="bi bi-recycle"></i>
                                </span>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
