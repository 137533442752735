<div class="main-wrapper">
    <div class="row">
        <div class="col">
            <h2>Realtime Errors</h2>
        </div>
        <div class="row">
            <div class="col button-container">
                <button
                    [disabled]="isLoading()"
                    type="button"
                    class="btn btn-info search"
                    (click)="refresh()"
                >
                    <i class="bi bi-arrow-clockwise"></i>
                    &nbsp;&nbsp;Refresh
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h3>Subscriptions Tata failed to add an AddOn:</h3>
            </div>
        </div>
        <div
            class="row"
            style="font-style: italic; margin-top: 20px; text-align: center"
            *ngIf="
                missingProcessableSubscriptions.length === 0 && fetchedMissings
            "
        >
            <div class="col">
                All Advertisement, Byte, Premium, Ultra and Limited
                subscriptions have been processed successfully.
            </div>
        </div>
        <div
            class="row table-container"
            *ngIf="missingProcessableSubscriptions.length > 0"
        >
            <div class="col">
                <table class="table table-light table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Plan Type</th>
                            <th scope="col">Date</th>
                            <th scope="col">UID</th>
                            <th scope="col">ICC ID</th>
                            <th scope="col">TransactionId</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sub of missingProcessableSubscriptions">
                            <td>{{ sub.planType }}</td>
                            <td>
                                {{ sub.createdAt | date : "dd-MM-YY HH:mm OO" }}
                            </td>
                            <td>{{ sub.uid }}</td>
                            <td>
                                {{ sub.iccId }}
                            </td>
                            <td>
                                {{ sub.transactionId }}
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-tertiary"
                                    aria-expanded="false"
                                    (click)="retryAddOn(sub)"
                                    [disabled]="isLoading()"
                                >
                                    <span
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover focus"
                                        data-bs-custom-class="button-tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Try sending a new request to the provider"
                                    >
                                    <i class="bi bi-send-arrow-up-fill"></i>
                                </span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" style="margin-top: 40px">
            <div class="col">
                <h3>Subscriptions that have failed to roll over:</h3>
            </div>
        </div>
        <div
            class="row"
            style="font-style: italic; margin-top: 20px; text-align: center"
            *ngIf="
                failedProcessableSubscriptions.length === 0 && fetchedFaileds
            "
        >
            <div class="col">
                No failed processable subscriptions exist at the moment.
            </div>
        </div>
        <div
            class="row table-container"
            *ngIf="failedProcessableSubscriptions.length > 0"
        >
            <div class="col">
                <table class="table table-light table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Plan Type</th>
                            <th scope="col">Date</th>
                            <th scope="col">UID</th>
                            <th scope="col">ICC ID</th>
                            <th scope="col">TransactionId</th>
                            <th scope="col">AddOnInstanceId</th>
                            <th scope="col">Next Plan</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sub of failedProcessableSubscriptions">
                            <td>{{ sub.planType }}</td>
                            <td>
                                {{ sub.createdAt | date : "dd-MM-YY HH:mm OO" }}
                            </td>
                            <td>{{ sub.uid }}</td>
                            <td>
                                {{ sub.iccId }}
                            </td>
                            <td>
                                {{ sub.transactionId }}
                            </td>
                            <td>
                                {{ sub.addOnInstanceId }}
                            </td>
                            <td>
                                {{ sub.nextPlanType }}
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="
                                        sub.planType !== planTypes.PREMIUM &&
                                        sub.planType !== planTypes.ULTRA
                                    "
                                >
                                    <button
                                        class="btn btn-secondary"
                                        type="button"
                                        id="button-days"
                                        (click)="
                                            deleteProcessableSubscription(
                                                sub.transactionId
                                            )
                                        "
                                        [disabled]="isLoading()"
                                    >
                                        <span
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover focus"
                                            data-bs-custom-class="button-tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Delete this subscription"
                                        >
                                            <i class="bi bi-trash2-fill"></i>
                                        </span>
                                    </button>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        sub.planType === planTypes.PREMIUM ||
                                        sub.planType === planTypes.ULTRA
                                    "
                                >
                                    <button
                                        class="btn btn-quaternary"
                                        type="button"
                                        id="button-days"
                                        (click)="
                                            reprocessProcessableSubscription(
                                                sub.transactionId
                                            )
                                        "
                                        [disabled]="isLoading()"
                                    >
                                        <span
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover focus"
                                            data-bs-custom-class="button-tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Reprocess this subscription"
                                        >
                                            <i class="bi bi-shuffle"></i>
                                        </span>
                                    </button>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
