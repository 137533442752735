import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/users/user';
import { DateParser } from 'src/app/shared/utils/date-parser';

@Component({
  selector: 'app-manage-user-details',
  templateUrl: './manage-user-details.component.html',
  styleUrls: ['./manage-user-details.component.scss']
})
export class ManageUserDetailsComponent implements OnInit {
  @Input() private user: User;
  public userFields: any[] = [];
  constructor() { }

  ngOnInit(): void {
    this.processUser()
  }

  private processUser(): void {
    if (this.user) {
      this.userFields.push(
        { key: "User Id", value: this.user.uid },
        { key: "Name", value: this.user.userName },
        { key: "Phone Number", value: this.user.userPhoneNumber },
        { key: "Email", value: this.user.userEmail },
        { key: "Language", value: this.user.language },
        { key: "Previous Provider", value: this.user.previousProvider },
        { key: "Partner Id", value: this.user.partnerId },
        { key: "Waitlist Number Of Days", value: this.user.waitlistNumberOfDays },
        { key: "Migration Date", value: DateParser.getDate(this.user.migrationDate) },
        { key: "Old Icc Id From Maya", value: this.user.oldIccId },
        { key: "Test User", value: this.user.isTestUser },
        { key: "Creation Date", value: DateParser.getDate(this.user.userCreationDate) },
        { key: "Deletion Date", value: DateParser.getDate(this.user.userDeletionDate) },
        { key: "Bought Premium Before", value: this.user.hadPremium },
        { key: "Byte Flow Allowed", value: this.user.byteFlowAllowed },
        { key: "Referred By", value: this.user.referredBy },
      );
    }
  }
}
