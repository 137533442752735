import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/shared/models/api-response";
import { Payment } from "../models/payment";

@Injectable()
export class PaymentService {

    public constructor(
        private readonly httpClient: HttpClient
    ) { }

    public listAuthorizedPaymentsOfAllUsers(): Observable<ApiResponse<Payment[]>> {
        return this.httpClient.get<ApiResponse<Payment[]>>("/api/payments/adyen/authorized/all");
    }

     public listAuthorizedPaymentsOfUser(uid: string): Observable<ApiResponse<Payment[]>> {
        return this.httpClient.get<ApiResponse<Payment[]>>(`/api/backoffice/payments/${uid}`);
    }
}
