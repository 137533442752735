<nav class="navbar navbar-light bg-light px-2">
    <form class="form-inline">
        <button [disabled]="isLoading()" type="button" [class.btn-success]="!isLoading()" [class.btn-outline-secondary]="isLoading()" class="btn" (click)="exportToCSV()">Export to CSV</button>
    </form>
  </nav>
<table id="tblMembers" class="table table-striped table-hover table-lg table-responsive">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col" sortable="uid" (sort)="onSort($event)">UID</th>
            <th scope="col" sortable="pspReference" (sort)="onSort($event)">PSP Reference</th>
            <th scope="col" sortable="paymentReference" (sort)="onSort($event)">Payment Reference</th>
            <th scope="col" sortable="eventDate" (sort)="onSort($event)">Payment Date</th>
            <th scope="col" sortable="status" (sort)="onSort($event)">Payment Status</th>
            <th scope="col" sortable="success" (sort)="onSort($event)">Successful?</th>
            <th scope="col" sortable="currency" (sort)="onSort($event)">Currency</th>
            <th scope="col" sortable="amount" (sort)="onSort($event)">Amount</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let payment of (sortablePayments | async); index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td><a [href]="payment.userFirestoreLink" target="_blank">{{ payment.uid }}</a></td>
            <td><a [href]="payment.paymentLink" target="_blank">{{ payment.pspReference }}</a></td>
            <td>{{ payment.paymentReference }}</td>
            <td>{{ payment.eventDate | date : "MMM d, y, h:mm:ss a" }}</td>
            <td>{{ payment.status }}</td>
            <td>{{ payment.success }}</td>
            <td>{{ payment.currency }}</td>
            <td>{{ payment.amount / 100 }}</td> <!-- unsafe monetary math here... -->
        </tr>
    </tbody>
</table>
