import { Component, Input, OnInit } from '@angular/core';
import { PromoCode } from '../../models/promo-codes/promo-code';
import { PromoCodeDiscountType } from '../../enums/promo-code-discount-type';
import { PromoCodeRequest } from '../../models/promo-codes/promo-code-request';
import { PromoCodeHttpService } from '../../services/promo-code.http.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { PlanType } from '../../enums/plan-type';

@Component({
  selector: 'app-edit-promo-code',
  templateUrl: './edit-promo-code.component.html',
  styleUrls: ['./edit-promo-code.component.scss']
})
export class EditPromoCodeComponent implements OnInit {
  @Input() public promoCode: PromoCode;

  constructor(
    private readonly promoCodeHttpService: PromoCodeHttpService,
    private readonly toasterService: ToasterService
  ) { }

// optional: Record type annotation guaranties that 
// all the values from the enum are presented in the mapping
 promoCodeMapping: Record<PromoCodeDiscountType, string> = {
  [PromoCodeDiscountType.DAYS]: "Days",
  [PromoCodeDiscountType.FIXED]: "Fixed",
  [PromoCodeDiscountType.PERCENTAGE]: "Percentage",
  [PromoCodeDiscountType.FIRST_REGISTER_DAYS]: "First register days for partner",
};

promoCodePlanTypeMapping: Record<PlanType | any, string> = {
  [PlanType.PREMIUM]: "Premium",
  [PlanType.ULTRA]: "Ultra"
};

promoCodeDiscountTypes = Object.values(PromoCodeDiscountType);
promoCodePlanTypes = [PlanType.PREMIUM, PlanType.ULTRA];

  ngOnInit(): void {
  }

  public editPromoCode(): void {
    this.promoCode.creationDate = new Date(this.promoCode.uiCreationDate);
    this.promoCode.endDate = new Date(this.promoCode.uiEndDate);
    const promoCodeCreateRequest: PromoCodeRequest = {
      name: this.promoCode.name,
      code: this.promoCode.code,
      discount: this.promoCode.discount,
      discountType: this.promoCode.discountType,
      maximumPromoCodeUsers: this.promoCode.maximumPromoCodeUsers,
      endDate: this.promoCode.endDate,
      limitedToOneUsePerUser: this.promoCode.limitedToOneUsePerUser,
      partnerId: this.promoCode.partnerId
    };

    this.promoCodeHttpService.updatePromoCode(promoCodeCreateRequest).subscribe(
      () => {
        this.toasterService.success('Promo code updated successfully');
      },
      () => {
        this.toasterService.error('Failed to update promo code');
      }
    );
  }

}
