import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { UserHttpService } from "../../services/user.http.service";
import { MemberWithData } from "src/app/modules/dashboard/modules/admin/models/member-with-data";
import { map, mergeMap, scan, shareReplay, startWith, switchMap } from "rxjs/operators";
import { BehaviorSubject, Observable, from, merge, of } from "rxjs";
import { UserRole } from "src/app/shared/enums/user-role";
import { SortableDirective } from "src/app/shared/directives/sortable.directive";
import { SortEvent } from "src/app/shared/events/sort.event";
import { Member } from "src/app/shared/models/member";
import { RequestTrackerService } from "src/app/shared/services/request-tracker.service";
import * as Papa from "papaparse";

const defaultValue = (v: string | UserRole | Date): string | UserRole | Date => !!v ? v : ((v instanceof Date) ? new Date(0) : ""); // UserRole will arrive as string
const compare = (v1: string | UserRole | Date, v2: string | UserRole | Date): number => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
    selector: "membership",
    templateUrl: "./membership.component.html",
    styleUrls: ["./membership.component.scss"]
})
export class MembershipComponent implements OnInit {

    @ViewChildren(SortableDirective)
    private tableHeaders: QueryList<SortableDirective<MemberWithData>>;

    private members: Observable<MemberWithData[]>;

    private _sortableMembers: Observable<MemberWithData[]>;

    public get sortableMembers(): Observable<MemberWithData[]> {
        return this._sortableMembers;
    }

    private readonly membersSubject: BehaviorSubject<MemberWithData[]> = new BehaviorSubject<MemberWithData[]>([]);

    public constructor(
        private readonly userHttpService: UserHttpService,
        private readonly requestTrackerService: RequestTrackerService
    ) { }

    public async ngOnInit(): Promise<void> {
        //const listUsersResponse: Observable<Member> = this.membershipService.listMembers().pipe(mergeMap(listUsersResponse => from(listUsersResponse.data)));

        // this.members = listUsersResponse.pipe(
        //     mergeMap(user =>
        //         merge(
        //             of({ ...user, lastSignInDate: new Date(user.lastSignInUTCString), createdDate: new Date(user.createdUTCString) } as MemberWithData), // Emit user details first
        //             this.membershipService.getDataUsage(user.uid).pipe(
        //                 map(dataUsageResponse => ({ ...user, lastSignInDate: new Date(user.lastSignInUTCString), createdDate: new Date(user.createdUTCString), ...dataUsageResponse.data } as MemberWithData))
        //             ) // emit user & usage data together again to partially fill
        //         )
        //     ),
        //     scan((acc: MemberWithData[], curr: MemberWithData) => {
        //         const existingIndex = acc.findIndex((member: MemberWithData) => member.uid === curr.uid);

        //         if (existingIndex !== -1) {
        //             acc[existingIndex] = curr;
        //         } else {
        //             acc.push(curr);
        //         }

        //         return acc;
        //     }, [] as MemberWithData[])
        // );

        this.members.subscribe(members => this.membersSubject.next(members));

        this.updateSortableMembers();
    }

    public isLoading(): boolean {
        return this.requestTrackerService.isLoading();
    }

    public exportToCSV(): void {
        this.sortableMembers.subscribe((members: MemberWithData[]) => {
            if (members.length > 0) {
                const csvData = Papa.unparse(members);

                const blob: Blob = new Blob([csvData], { type: "text/csv" });
                const url: string = window.URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement("a");
                const date: Date = new Date();

                a.href = url;
                a.download = `members_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.csv`;

                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public onSort(sortEvent: SortEvent<MemberWithData>): void {
        this.tableHeaders.forEach(header => {
            if (header.sortable !== sortEvent.column) {
                header.direction = "";
            }
        });

        this.updateSortableMembers(sortEvent);
    }

    private sortMembers(members: MemberWithData[], sortEvent: SortEvent<MemberWithData>): MemberWithData[] {
        return members.sort((member1: MemberWithData, member2: MemberWithData) => {
            const res = compare(defaultValue(member1[sortEvent.column]), defaultValue(member2[sortEvent.column]));
            return sortEvent.direction === "asc" ? res : -res;
        });
    }

    private updateSortableMembers(sortEvent: SortEvent<MemberWithData> = null): void {
        this._sortableMembers = this.membersSubject.pipe(
            startWith([]),
            switchMap((members) => {
                if (!sortEvent || sortEvent.direction === "" || sortEvent.column === "") {
                    return of(members); // Return unsorted members if no sorting is applied
                } else {
                    return of(this.sortMembers([...members], sortEvent));
                }
            }),
            scan((acc, val) => val, [] as MemberWithData[]) // Scan to accumulate the latest sorted members
        );
    }

    public isNaN(value: number): boolean {
        return Number.isNaN(value);
    }
}
