import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { mergeMap } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class FirebaseIdTokenInterceptor implements HttpInterceptor {

    public constructor(
        private readonly firebaseAuthService: FirebaseAuthService
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.firebaseAuthService.idToken.asObservable()
            .pipe(mergeMap((idToken: string) => {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${idToken}`
                    }
                });

                return next.handle(request);
            }));
    }
}
