import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from "@angular/fire/auth-guard";

const redirectUnauthorizedToMainPage = () => redirectUnauthorizedTo(["/"]);

const routes: Routes = [{
    path: "",
    children: [{
        path: "dashboard",
        loadChildren: () => import("./modules/dashboard/dashboard.module").then(m => m.DashboardModule), // new lazy loading. cool!
        ...canActivate(redirectUnauthorizedToMainPage)
    }]
}, {
    path: "checkout",
    children: [{
        path: "",
        loadChildren: () => import("./modules/payment-checkout/payment-checkout.module").then(m => m.PaymentCheckoutModule), // new lazy loading. cool!
    }]
},{
    path: "**",
    redirectTo: "/" // redirecting to root does not do its own redirection logic for some reason, therefore redirecting to dashboard directly.
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
