import { Component, OnInit } from '@angular/core';
import { SettingsModal } from '../../../enums/settings-modal';
import * as bootstrap from "bootstrap";
import { SettingsHttpService } from '../../../services/settings.http.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { ByteFlowSettings } from '../../../models/settings/byte-flow-settings';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
    private settingsHttpService: SettingsHttpService,
    private toasterService: ToasterService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getByteFlowSettings();
    });
  }

  showModals: boolean = false;
  modalOptions = SettingsModal;
  selectedModal: SettingsModal;
  byteFlowSettings: ByteFlowSettings = {
    megabytesAtStart: 0,
    megabytesPerTab: 0,
    userMbsPerInvitation: 0,
    friendMbsPerInvitation: 0,
    friendMbsForFirstPurchaseEnabled: false,
    friendMbsForFirstPremiumPurchase: 0,
    friendMbsForFirstUltraPurchase: 0,
    friendInvitationLimit: 0,
    bytePlanActivationLimit: 0,
    byteFlowAllowedForAll: false
  }
  
  private initialiseToolTips(): void {
    setTimeout(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      })
    });
  }

 public manageByteFlowSettings(modal: SettingsModal): void {
    this.showModals = true;
    const tooltipElements = Array.from(document.querySelectorAll('.bs-tooltip-auto'));
    for (const item of tooltipElements) {
      item.remove();
    }
    this.selectedModal = modal;
    this.initialiseToolTips();
  }

  
  public getByteFlowSettings(): void {
    this.settingsHttpService.getByteFlowSettings().subscribe(
      (response) => {
        this.byteFlowSettings = response.data;
        this.toasterService.success('Byte flow settings loaded successfully');
      },
      () => {
        this.toasterService.error('Failed to load byte flow settings');
      }
    );
  }
}
