import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse } from "../models/api-response";
import { Member } from "../models/member";

@Injectable({
    providedIn: "root"
})
export class ClaimsService {

    public constructor(
        private readonly httpClient: HttpClient
    ) { }

    public claimAdmin(uid: string): Observable<ApiResponse<Member>> {
        return this.httpClient.post<any>(`/api/backoffice/claims/admin/${uid}`, null);
    }
}
