import { Component, Input, OnInit } from '@angular/core';
import { PromoCode } from '../../models/promo-codes/promo-code';
import { PromoCodeDiscountType } from '../../enums/promo-code-discount-type';
import { PromoCodeHttpService } from '../../services/promo-code.http.service';
import { PromoCodeRequest } from '../../models/promo-codes/promo-code-request';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { PlanType } from '../../enums/plan-type';

@Component({
  selector: 'app-create-promo-code',
  templateUrl: './create-promo-code.component.html',
  styleUrls: ['./create-promo-code.component.scss']
})
export class CreatePromoCodeComponent implements OnInit {
  public promoCode: PromoCode = {
    name: "",
    code: null,
    discount: 0,
    discountType: PromoCodeDiscountType.DAYS,
    maximumPromoCodeUsers: 0,
    remainingPromoCodeUsers: 0,
    creationDate: new Date(),
    endDate: new Date(),
    limitedToOneUsePerUser: false,
    usedBy: [],
    partnerId: null
  };

  constructor(
    private readonly promoCodeHttpService: PromoCodeHttpService,
    private readonly toasterService: ToasterService
  ) { }

  promoCodeMapping: Record<PromoCodeDiscountType, string> = {
    [PromoCodeDiscountType.DAYS]: "Days",
    [PromoCodeDiscountType.FIXED]: "Fixed",
    [PromoCodeDiscountType.PERCENTAGE]: "Percentage",
    [PromoCodeDiscountType.FIRST_REGISTER_DAYS]: "First register days for partner",
  };

  promoCodeDiscountTypes = Object.values(PromoCodeDiscountType);


  promoCodePlanTypes = [PlanType.PREMIUM, PlanType.ULTRA];
  promoCodePlanTypeMapping: Record<PlanType | any, string> = {
    [PlanType.PREMIUM]: "Premium",
    [PlanType.ULTRA]: "Ultra"
  };

  ngOnInit(): void {
  }

  createPromoCode(): void {
    console.log(this.promoCode);

    const promoCodeCreateRequest: PromoCodeRequest = {
      name: this.promoCode.name,
      code: this.promoCode.code == "" ? null : this.promoCode.code,
      discount: this.promoCode.discount,
      discountType: this.promoCode.discountType,
      maximumPromoCodeUsers: this.promoCode.maximumPromoCodeUsers,
      endDate: this.promoCode.endDate,
      limitedToOneUsePerUser: this.promoCode.limitedToOneUsePerUser,
      partnerId: this.promoCode.partnerId ?? null,
      planType: this.promoCode.planType ?? null
    };

    this.promoCodeHttpService.createPromoCode(promoCodeCreateRequest).subscribe(
      () => {
        this.toasterService.success('Promo code created successfully');
      },
      () => {
        this.toasterService.error('Error creating promo code');
      }
    );
  }
}
